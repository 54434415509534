/*

    This library is created by Franco(Xufei Sun) for Articulate Pty Ltd on 21/11/2016
    All rights reserved.

    This library is based on jQuery and dataTable; bootstrap and jedate makes it better).


*/


/*
    功能实现介绍
    -------
    1 ajax_form | ajax_form是一个将需要用ajax提交的form统一封装 而可以使用与原始form类似的设置方式的plugin； 只依赖于jQuery
        主要的方便之处在于 
            - 自动化validation以及对于serverMsg的翻译与显示
            - 在traditional form与ajax form之间切换非常方便

        A. 在不需要发送的input上面加上 notsent class :  比如 password_confirmation / hidden  即使hidden也可以不发送
        B. 给必填项加上 required

        D. validate中 除了对required的input项有专门的验证 针对不用的name也有不同的规则
            验证分为三轮 第一轮特殊型检查（可能还需要弄一个专门的register接口） 第二轮format  第三轮验证required
            第二轮format是与input field的 data-format比对（多需要加上^和$）； 对应的错误提示 可以用data-formatmsg个性化


        E. 在表单的最后 可以加一个  <span class="loader formSubmitStyle"></span> 并针对性调整好css 作为发送请求之后 收到回复之前的样式
           如果还有其他的地方的样式需要改变 也可以加上 formSubmitStyle 并且对本元素加上formSubmitted class之后的style进行设计； lib会自动
            add/remove class; submit button在提交之后也会变成disabled
        F. 准备发送ajax call的时候 stateMap.executing_formName 会保存当前的formName； 这是为了callback执行时候的方便 在完成
            completeCallback时候消除问题是如果几乎同时发送多个ajax_form可能会有race condition
        G. 关于filed title：需要在input旁边加上 span.field_title 在其中放入原来可能会用placeholder放的内容； 注意 如果使用了jedate的话 
           需要手动加上  choosefun: function(elem, val) { $(elem).addClass('filled'); },    && clearfun
           ---> filled这个class在input上决定了title的表现
        H. 现在的实现 一定要用一个relative label把各项包起来；各种提示性的东西全部用absolute做；（甚至可以加一个lib中的class：invalid_message）
        I. 默认的dataType为text； 如果是post的话 还会有默认contentType为 application/json
      ! J. 要加上 articulate_ajaxForm class给form
        K. span.input-field, span.invalid_msg都会存在 但是style需要针对性设置
        L. data-realtime-validation 默认返回success（text）即成功
        M. serverMsgHandler处理已经预料到的错误列表； errorCallback用于处理未知的错误； successCallback用于处理既定的目标完全达到的成功； 
            successResponse 是成功时server返回的值；errorResponse会接一个包含responseText target message的array
            如果target设置为 _alert 就会将预设好的message 弹出来
        N. 
            ajax response 成功：1 被抓住的返回 － serverMsgHandler； 
                               2 其他返回 － 触发 successCallback； 

            ajax response 失败：1 被抓住的返回 － 触发 serverMsgHandler； 
                               2 其他返回 － 触发errorCallback

           
        O. clearUponComplete 设置为true或者1的话（其实只要会被JS判定为true） 在收到任何回复的时候都会清除所有input/select项
           设置 nottoclear class去阻止清空
            另外有clearUponSuccess 只有返回成功时才会清空

        P. colDefs -> myCustom -> actions 一项有 colTitle； 如果有设置的话
            再加了一个 buttonTitle： 如果有dropdown button的话 button上面的显示内容

            colDefs又加了一项 format： 使用方法是 1234__.2__  data会将__XX__中的部分替换掉 有小数点的话使用toFixedto
        myCustom and format can only choose one


        Q. 【【【【 现在如果一个input的value为空的话 就不会发送了 为后端判断提供了帮助 但可能会在未来有问题 】】】】
        R. finalDataConfig!!! 在发送之前对于数据做的最后处理； 将处理后的 data 返回
        S. 在form中加上 clearAll class的element 点击后会清空所有input collection部分（暂时还没有包括radio）
            --> clearAll will clear trigger clearAllInvalidMsg now --> make the form back to initial state

        traditional form中 没有name的自动就不会被extract出来提交 但是我的form中需要手动去做： 与notsent在同一个位置
        空的field不会被提交

        T. externalClear 点击clear的时候 在默认clearAll基础上额外的功能执行 传入了form本身的element作为可利用的参数


    2 DT ｜ DT是一个基于dataTable(所以也基于jQuery & Bootstrap)的 主要为了封装统一 包含自定义搜索栏、row操作栏、多选checkbox的
            table的 plugin 
        A. 
        B. 如果没有设置language； 会自动选择默认的中文提示文字
            language: {preefined: 'english'}  会使用系统默认的english

        C. 有几个默认的设置 暂时还没有方法改变： stateSave / pagingType / lengthMenu
        D. search条件太多的话 使用lineBreaker分成两行
        E. 需要统计数据的column 给一个与data同名的name
        F. 在一个页面中只能操作一个table
        G. filter中的select： 只有写了criteria.defaultContent 才会在可选项之外加一个placeholder一类的没有实际value的option
        H. 为了让search能用 需要在type+keyword的type上加上name
        I. search中checkbox的设置中 data: 'method' 一栏 的意义是这个值是对于table中哪个column去匹配; 相应的这个column需要加上这个名字
            lookfor （因为有可能有render -- data与display不一致） lookfor的引入其实不太有用 -- 只是为了万一
            {
                displayTitle: '红利',
                title: 'hongli',
                type: 'checkbox',
                data: 'method',
                lookfor: '红利'
            }
         select中也有类似的设置 暂时只支持单选：多选可用checkbox实现； 

         select中不选择option时的那个label 要以"请选择/全部/不限"开头
         select的对象由against data设置； 标准由value设置（render之后的值）； 
         select设置为字符段完全匹配 (因为会有 “激活”vs“未激活”； 不能用string查找)

         ################
            总结： search
                1 search 的 defs中常要加一栏'data' 是指的这个数据应该从什么column去找 对应的column也需要加上一个name 两者得相同
                2 type+kw中的几个column都需要给columnDefs加上name; 这个name需要与type那个select的各个option的value相吻合
                3 单纯select中 没有value的option需要以“请选择/全部/不限”开头（table中肯定是display用数据；但option中display和value是分开的 value本身
                    常是英文无法与table中的display(常是中文)匹配 而table也不方便改成与value相匹配 解决方法是读取了option的display 
                    但是新的问题就出现了：即使value为空的option display肯定也是有的 无从判断是否是中文 于是加了一个限制：必须要以“请选择/全部/不限”开头） 

         ################

         J. actions: [
                {
                    displayTitle: '审核',
                    classes: 'audit'
                }
            ],
            classes会加到对应的button上；方便之后的event handler ；可以不加
        K. column defination中加了一个fastInquire作为custom 主要的对象是每一个列出一个可以点击 然后与fastInquire联合使用的
            具体的sample在allplayer中
            !!!!  要给fastInquire加几个属性让它可以与fastInquire联合使用（还要声明他们！！）  !!!!!
        L. rowId可以选择设置 config 名字为rowIdReference ； 接上一个string用于表示在data source中提取什么值来作为id
        M. callbackDefs 传入一个array 其中每个object 有target与method target是对应的class method可以用本条目的全部data source作为参数
            只可以用来对于单条目中的操作进行设置

            callbackDefs 的callback method新加入了optional的第二个参数 可以拿到触发callback的event本身；用来处理 
                同类型的请求处理
            

        N. 需要destroy掉当前datatable的时候 对table trigger一下 'destroy' event; 虽然可以不加但为了保险一般还要加上empty()
            另外 添加新纪录入table也是相同的方式 $('table').trigger('newRecord',newRecord);  newRecord接受object

        O. batch已经实现 设置方法为          可以直接调用data data是选中的row的数据   batch必须与自定义column的 selectBox联合使用
        
            !!!! 要使用的时候 将传入data改为传入rows!!!!!!!!

                var batchOpsDefs = {
                    actions: [{
                        displayTitle: '批量调整红利',
                        method: function(data) {
                          console.log(data);
                        }
                    }],
                    dropdownMenu: false
                };
        P. 对于server msg的处理已经写好 但是！！必须与callbackDefs合并使用 callbackDefs会对当前的active row进行定义 在收到server回复的时候
            (实际上是在success/error callback中调用的；相当于收到回复的时候)
            直接调用才可以知道是什么row会被改变了 存在stateMap.activeRowApi  即使是在modal中实际发送的ajax也可以 因为active row并不会改变

            ####  实际上处理的不是server的msg 而是我在对server的msg的handler中另外trigger的event 所以可以更好的利用server回复的信息 #####

            具体的用法是 在收到确认的回复后加上 ----- $('#table-allPlayer').trigger('serverMsg', ['test', 'Hello World']);
                即需要传入本次server message的类型与回复的信息
            DT再加上一个option: msgHandlerDefs  为object  内部pair中： key为需要处理的msg-type value为对于该特定msg-type处理方式
                可以读取到服务器的回复信息与dataTable中对应row的api ----- test: function(replyData, rowApi) 


            -->  headerCallbackDefs 是add到header上面的callback 不会造成activeRowApi的变化 
                 这个Defs中的method也必须要小心使用 因为他得到的参数是dataTable的api本身 具有很大的权限

                 使用方法是 作为一个option传入constructor 包括target与method 值得注意的是 这个target必须要放在title的 #### span #### 中
                 这是为了保证准确性 应该也不会出现非文字/icon的奇怪的的结构了
                 method中会传入table 的api； 要对于column的visibility进行调整时 使用 api.column(XXXX:name) 找到 用visible进行调整
                 所以对应的column需要有name才可以
        

        Q. dataSource的来源可以是页面上已经存在的js array；也可以是一个api的url 在init method中有自动的判断

        R. 加入了一个 dvdTable event；  触发的时候 只使用部分数据进行构造；实际上是search的结果 
            与search的主要区别在于 这里的总数也设置成search的结果

           search的情况下 进一步搜索 都只能使用divide之后数据

           dvdTable的第二个参数 传 {'columnName1':'target1', 'columnName2':'target2' }  如果传入false则返回全部结果

           可以在dividing之后 与自定义的search混合使用 但是如果用了默认的搜索栏 应该会出错（在整个table搜索了）

           dvdTable可以summaryDefs结合使用 -- 但是暂时的解决方案是 summaryDefs显示filter之后的结果


        S. 
            显示filter之后的结果统计

               summaryDefs: [
                    {
                        targetData: '', // name
                        range: '', //current / all(不知道是不是 all)
                        method:  // 不填就是取总 填average就是平均值
                        displayContext:  // 输出的格式 string  __result__部分会被替换掉
                        preprocessing:  // 一个function 每个value可以预处理再加上去
                    }

                ]


    
    3 modalCtrl | 在table中要对一项或多项entry进行操作的时候弹出的一个modal；需要找一个合适的（也可以不找）的plugin作为基础；可能会用到iframe；
        有广泛的应用 需要可以accomadate各种form ; 需要与component中一个blade共同使用
        A. 在element上加上dataContainer class（目前支持input的name识别 以及其他element的 data-field识别）  配合modalCtrl.populate({})使用
            就可以将object中的key-value pair注入view； 在modalCtrl.open中已经写好的clear 清空之前留下的data 
            （因此 populate一定要在open之后使用！！！！！否则会被清空掉）
            
            !!! 即使不是用populate加入的部分 也可以加上dataContainer class；这样的话open的时候就可以自动清空了


        B. open 中加入了 clearData 会将
           还会先判断是否已经打开（bootstrap的modal如果连续 modal('show')两次 就关不掉 暂时不清楚为什么） 
           这样的话 在modal中做的数据改变 可以用直接从数据库再拿一次数据 直接刷新了（因为open经常是和拿数据在同一个function中）

        C. config中加入了customSetup一个选项 其中 setup是必须的function 另外可以存一些数据在里面
                customSetup: {
                    setup: function(data, $modal) {
                        data.autoTopElements.trigger('click');
                    },
                    data: {
                        autoTopElements: $('ul.nav-tabs-line li:first-child a')
                    }
                }
            data会被存储在modalCtrl内；setup的时候可以调用 
            第二个参数为modal的wrapper的jquery element

            modalCtrl.config    width height title等参数可以单独设置 ； customSetup也可以单独设置（后来加上）


        C. populate now works even if same dataContainer appears multiple times


    4 fastInquire | 主要的功能是让一个minified的element点击之后去查询一个数据 并在原来的位置显示结果；参数用 data-* 设置好
        A. 应该是用get 但是具体的地址 以及地址的计算合成要等到实现的时候才知道
        B. 可能要给对象加一个 fastInquire class 以精确的找到
        C. 所有 param都应以 param-id开头 暂时只能全小写
        D. 加上了一个 data-display 其中的_reply_会被回复的message所代替
        E. data-replyformat 里面放regular expression； 不满足对应格式的reply message会被视为错误 （！！ 常会使用 ^ 与 $ 在前后表示完全匹配）


    5 apiTester | api test tool 
        A. apiTester.post('/','tester=asdasd&weather=sunny');
        B. apiTester.get('/sdaesdasc');
        C. 现在API Tester多了一个参数是customeData 作用时直接使用其中的值作为load发送请求 但需要用ifUserForm而没有用它的时候 要给他设置成false

    6 regi (register)

    7 ajaxPolling | timer interval ajax call

        
    8 ssExtend | a small tool to store and retrieve objects into and from sessionStorage

    9 timer | a library to set renewable timeout

    10 immComplete | immediate completion -- 在初始化了的input中 输入string 根据他的配置 在server上或者页面上的数据 
        可以给出匹配的完全目标 单击即可填写

        现在可用的参数有 data-time='500' data-noresult="无结果" data-source="games" data-prop="name" data-real="id" >
        使用方式为 $('.immComplete').immComplete();
        data-prop为在array中去匹配的对象 可不填则使用整个element data-real尚未实现

    11 strg | 用来尽可能将可以cache的数据cache了
        可以设置 
            1 使用localStorage还是sessionStorage 使用localStorage的话过期时间是什么
            2 去什么地方拿数据； 失败的话默认数据是什么（虽然不会有意义 但可以避免出错）
            3 不需要人为设置存储的名字 
        拿到数据之后的操作 必须要作为callback

        // strg.config({
            playerDetails: ['/member/details']
        })

        strg.get('playerDetails',{'id':'007'})

*/

/*
    尚未完成的部分
    ------------
    现在plugin的错误提示几乎没有； 对于user input的准确／完整性要求很高 都是需要进一步优化的

    ____________
    1. ajax_form
    ------------
     甲. radioGroups直接是从radioCollection.parent().parent() 拿到的 对于html的结构有要求 不是很智能

    _________
    | 2. DT |
    ---------
     
     乙. 还需要加入相当量的event handler； 才可以有一个正常运行的table （各个element的class都已经很好的定义 应该不会很困难）
         table中的action button可以直接通过column的configs参数传入
         search在searchDefs中设置； batch在searchDefs中设置
     丙. filter date range这个部分要认真看一下怎么做
     丁. 需要建立一个data structure专门存放选择了的 checkbox 
    

     甲乙丙丁戊己庚辛

*/

'use strict';

var ajax_form, slotList, DT, gameOpener, modalCtrl, fastInquire, apiTester, regi, evnt, ajaxPolling, ssExtend, timer, immComplete, strg;

strg = (function() {

    var init, get;
    var storage = {};
    var configMap = {
        storage: null
    }
    var settingMap = {

    }
    var stateMap = {
        counter: null,
        data: null,
        callback: null
    }

    init = function(type, configs) {
        switch (type) {
            case 'localStorage':
                configMap.storage = lsExtend;
            case 'sessionStorage':
            default:
                configMap.storage = ssExtend;
        }

        for (var data in configs) {
            if (configs.hasOwnProperty(data)) {
                settingMap[data] = {
                    method: configs[data][0],
                    url: configs[data][1],
                    data: configs[data][2] // null if none
                }
            }
        }

        console.log(settingMap);
    }

    get = function(datas, callback) {
        if (!datas || !Array.isArray(datas) || datas.length < 1) return false;

        stateMap.callback = callback || $.noop;
        startHitingCache(datas.length);

        for (var i = 0; i < datas.length; i++) {
            if (storage[datas[i]]) extract(datas[i], storage[datas[i]]);
            else {
                // 没有cache
                // 先确定带不带参数
                var entry = datas[i].split('?')[0],
                    params = datas[i].split('?')[1];
                if (!setting[entry]) return false; // 验证一下是否有错误

                request( setting[entry], params ,entry);
            }
        }
    }

    var startHitingCache = function(total) {
        stateMap.counter = total;
        stateMap.data = {};
    }

    var extract = function(key, value) {
        stateMap.data[key] = value;
        stateMap.counter--;
        if (stateMap.counter <= 1) stateMap.callback(stateMap.data);
    }

    // final data storage
    var request = function(settings, params, name) {
        if (settings.method == 'post' || settings.method == 'POST') {
            // 检查是否完整
            var tmp = urlToObj(params);
            for (var i=0; i<settings.data.length;i++) {
                if (!tmp[ settings.data[i] ]) {
                    alert('not enough parameters!!');
                    return false;
                }
            }
            
            $.ajax({
                method: 'post',
                url: settings.url,
                dataType: 'text',
                data: tmp, // unfinished
                success: function(data) {
                    console.log(data);
                    console.log('成功！');

                    storage[settings.url + params] = data;
                    stateMap.data[name] = data;
                    stateMap.counter--;
                    if (stateMap.counter <= 1) stateMap.callback(stateMap.data);
                }
            })
        } else if (settings.method == 'get' || settings.method == 'GET') {
            $.ajax({
                method: 'get',
                url: settings.url + params, // unfinished
                dataType: 'text',
                success: function(data) {
                    console.log(data);
                    console.log('成功！');
                    storage[settings.url + params] = data;
                    stateMap.data[name] = data;
                    stateMap.counter--;
                    if (stateMap.counter <= 1) stateMap.callback(stateMap.data);
                }
            })
        }

        // stateMap.counter--;
        // if (stateMap.counter <= 1) stateMap.callback(stateMap.data);
    }

    return {
        init: init,
        get: get
    }
})();

/*
        ####################################################################################

                                         ajax_form 

        ####################################################################################
*/


immComplete = (function() {
    // 需要支持多个element在同一页面中

    var stateMap, jqueryMap;

    var init, match, reset; //util methods

    var onInput, onDisplayResultQualified, onSelect; //event listener


    jqueryMap = {
        $screenWrapper: null,
        resultWrappers: []
    }

    stateMap = {
        $activeElem: null,
        $activeResultsWrapper: null,
        $activeResults: null
    }

    init = function($elem) {
        timer.config($elem.data('time'));

        $elem.on('keyup', function(event) {
            if (event.keyCode == 9 || event.keyCode == 13) {
                reset();
                timer.clear();
            } else {
                stateMap.$activeElem = $(this);
                timer.restart();
            }
            return true;
        });

        $elem.on('blur', function(event) {
            if (!stateMap.$activeElem) return false;

            reset();
            timer.clear(); //停止计时
        });

        var $tmp_element = $('<div>').addClass('immComplete-matches state-hidden').append($('<ul>'));


        $elem.parent().append($tmp_element);
    }

    reset = function() {
        if (stateMap.$activeResultsWrapper) stateMap.$activeResultsWrapper.addClass('state-hidden');
        if (stateMap.$activeResults) {
            stateMap.$activeResults.empty();
            stateMap.$activeResults.off();
        }

        stateMap.$activeResults = null;
        stateMap.$activeResultsWrapper = null;

        stateMap.$activeElem = false;
    }

    match = function(search, array, prop) {
        // console.log('matching!!!');
        var tmp_array = [];
        search = new RegExp(search, 'gi');

        if (prop) {
            if (Array.isArray(array) && search) {
                for (var i = 0; i < array.length; i++) {
                    // console.log( array[i][prop].match(search) );
                    if (array[i][prop].match(search)) {
                        tmp_array.push(array[i][prop]);
                    }
                    // if (array[i].match(/ain/g);)
                }
            }

        } else {
            if (Array.isArray(array) && search) {
                for (var i = 0; i < array.length; i++) {
                    // console.log( array[i][prop].match(search) );
                    if (array[i].match(search)) {
                        tmp_array.push(array[i]);
                    }
                }
            }
        }

        // console.log(tmp_array);
        return tmp_array;
    }


    onSelect = function(event) {

        stateMap.$activeElem.val($(this).text());

        reset();

        return false;
    }

    onDisplayResultQualified = function(event) {
        var $elem = stateMap.$activeElem;
        if (!$elem) return false; // 已经被处理了

        // element setup
        var input = $elem.val();
        stateMap.$activeResultsWrapper = $elem.siblings('.immComplete-matches'),
            stateMap.$activeResults = stateMap.$activeResultsWrapper.find('ul');

        stateMap.$activeResults.empty();

        // souce powered
        if ($elem.data('source')) {
            var tmp_source = $elem.data('source'),
                tmp_prop = $elem.data('prop');

            var tmp_result = match(input, window[tmp_source] || [], tmp_prop);
            // searching

            if (tmp_result.length == 0)
                stateMap.$activeResults.append($('<li>').text($elem.data('noresult')));
            else
                for (var i = 0; i < tmp_result.length; i++) {
                    stateMap.$activeResults.append($('<li>').addClass('data').text(tmp_result[i]).on('click', onSelect).attr('data-real', tmp_result[i][$elem.data('real')]));
                }

            stateMap.$activeResults.on('mousedown', function(event) {
                event.preventDefault();
            }).on('click', 'li.data', onSelect);
        }

        stateMap.$activeResultsWrapper.removeClass('state-hidden');
    }

    jQuery.fn.extend({
        immComplete: function() {
            $(document).on('articulateJS_timeout', function(event) {
                onDisplayResultQualified(event);
            });

            return this.each(function(index, elem) {
                init($(elem));
            });
        }
    });

})();

timer = (function() {
    var config, start, restart, clear;

    var period, timer;

    config = function(time) {
        timer = Number(time);
        period = time;
    }

    start = function() {
        timer = setTimeout(function() {
            $(document).trigger('articulateJS_timeout');
        }, period);
    }

    restart = function() {
        clearTimeout(timer);

        timer = setTimeout(function() {
            $(document).trigger('articulateJS_timeout');
        }, period);
    }

    clear = function() {
        clearTimeout(timer);
    }

    return {
        config: config,
        start: start,
        restart: restart,
        clear: clear
    }

})();


ajaxPolling = (function() {
    var init, config, start, terminate;

    var stateMap = {
        url: '',
        dataType: 'json',
        method: 'get',
        replyHandler: $.noop(),
        interval: 60000
    }

    var polling;


    /*
        configs = {
            url:
            dataType:
            method:
            replyHandler:
            interval:
        }
    */

    config = function(configs) {
        $.extend(stateMap, configs);

        var tmp_token = $('meta[name="csrf-token"]').attr('content');
        if (tmp_token) $.ajaxSetup({ headers: { 'X-CSRF-TOKEN': tmp_token } });
    }

    start = function() {
        polling = setInterval(function() {
            $.ajax({
                url: stateMap.url,
                contentType: "application/json; charset=UTF-8",
                dataType: stateMap.dataType,
                method: stateMap.method,
                success: function(message) {
                    stateMap.replyHandler(message.responseText ? message.responseText : message);
                },
                error: function(message) {
                    alert('error！！！!');
                    console.log(message);
                }
            });

        }, stateMap.interval);
    }

    terminate = function() {
        clearInterval(polling);
    }


    return {
        config: config,
        start: start,
        terminate: terminate
    }

})();


apiTester = (function() {

    var ajax_setup, postDataProcess, HTMLfileChineseCharCheck, ajaxReplyHandler, traditionalFormHandler, ChineseCharEscape;

    var test, post, get; //public methods

    ajax_setup = function() {
        var tmp_token = $('meta[name="csrf-token"]').attr('content');
        if (tmp_token) $.ajaxSetup({ headers: { 'X-CSRF-TOKEN': tmp_token } });
    }

    postDataProcess = function(rawData) {
        // input string; output object

        var data = {},
            dataArray = [],
            tmp_counter = 0,
            tmp_start = 0;

        for (var i = 0; i < rawData.length; i++) {
            if (rawData[i] === '{') tmp_counter++;
            else if (rawData[i] === '}') tmp_counter--;

            if ((rawData[i] === '&' || i === rawData.length - 1) && tmp_counter === 0) {
                if (rawData[i] === '&') dataArray.push(rawData.substring(tmp_start, i));
                else dataArray.push(rawData.substring(tmp_start, i + 1));
                tmp_start = i + 1;
            }
        }

        for (var i = 0; i < dataArray.length; i++) {
            var tmp_string = dataArray[i],
                tmp_equalSign = 0;

            for (var j = 0; j < tmp_string.length; j++) {
                if (tmp_string[j] === '=') {
                    tmp_equalSign = j;
                    break;
                }

            }
            var tmp_key = tmp_string.substring(0, tmp_equalSign);
            var tmp_value = tmp_string.substring(tmp_equalSign + 1, tmp_string.length);

            //str.indexOf("welcome");
            if (tmp_value.indexOf('=') >= 0) {
                tmp_value = tmp_value.slice(1, -1);
                data[tmp_key] = postDataProcess(tmp_value);
            } else
                data[tmp_key] = tmp_value;
        }


        return data;

    }

    /*
    postDataProcess = function(rawData) {
        var dataArray = rawData.split('&');
        var data={};

        for (let i=0; i<dataArray.length; i++) {
            var tmp_values = [];
            tmp_values = dataArray[i].split('=');
            data[tmp_values[0]] = tmp_values[1];
        }

        return data;
        //返回的是一个object
    }

    // check if is HTML file; escape UTF-8 chinese char code
    */
    HTMLfileChineseCharCheck = function(message) {

        if (typeof(message) === 'object') message = message.responseText;

        if (message.startsWith("<!DOCTYPE html>")) return '[apiTester] ############ 返回值为HTML页面  请在network中查看具体消息 ############';
        else {
            var r = /\\u([\d\w]{4})/gi;
            message = message.replace(r, function(match, grp) {
                return String.fromCharCode(parseInt(grp, 16));
            });
            return unescape(message);
        }
    }

    test = function() {
        console.log('[apiTester] Hello World!');
    }

    ajaxReplyHandler = function(isSuccess, message) {
        if (isSuccess) console.log('[apiTester] Ajax Call Completed!');
        else console.error('[apiTester] Ajax Call Failed!');
        console.log('[apiTester] ############ reply message is as below ############');

        console.info(HTMLfileChineseCharCheck(message));

        console.log('[apiTester] ############ reply message ends ############');
    }

    traditionalFormHandler = function(method, url, data) {
        var $tmp_postform;
        if (method === 'post') {
            $tmp_postform = $('<form/>').attr({
                method: 'post',
                action: url
            });

            var tmp_data = postDataProcess(data),
                tmp_formContent = '';
            for (var field in tmp_data) {
                if (tmp_data.hasOwnProperty(field)) {
                    tmp_formContent += '<input type="text" name="' + field + '" value="' + tmp_data[field] + '" />';
                }
            }
            tmp_formContent += '<input type="hidden" name="_token" value="' + $('meta[name="csrf-token"]').attr('content') + '"/>';
            $tmp_postform.html(tmp_formContent);
        } else if (method === 'get') {
            $tmp_postform = $('<form/>').attr({
                method: 'get',
                action: url
            });
            $tmp_postform.html('<input type="hidden" name="_token" value="' + $('meta[name="csrf-token"]').attr('content') + '"/>');
        } else {
            console.error('error!');
            return false;
        }

        $('body').append($tmp_postform);

        $tmp_postform.submit();

    }

    /*
    apiTester.get('/proxy/details/player-details?fromDate=2017-01-01&toDate=2017-03-20')
    */

    post = function(url, data, customData, ifUseForm) {
        if (ifUseForm == true) {
            traditionalFormHandler('post', url, data);
        } else {
            ajax_setup();

            $.ajax({
                url: url,
                contentType: "application/json; charset=UTF-8",
                dataType: 'text',
                data: (customData ? JSON.stringify(customData) : JSON.stringify(postDataProcess(data))),
                method: 'post',
                success: function(message) {
                    ajaxReplyHandler(true, message);
                },
                error: function(message) {
                    ajaxReplyHandler(false, message);
                }
            })
        }
    }

    get = function(url, customData, ifUseForm) {
        if (ifUseForm == true) {
            traditionalFormHandler('get', url);
        } else {
            ajax_setup();

            $.ajax({
                url: url + (customData ? '?' + $.param(customData) : ''),
                contentType: "application/json; charset=UTF-8",
                dataType: 'text',
                method: 'get',
                success: function(message) {
                    console.log('[apiTester] Ajax Call Completed!');
                    console.log('[apiTester] ############ reply message is as below ############');
                    console.info(HTMLfileChineseCharCheck(message));
                    console.log('[apiTester] ############ reply message ends ############');
                },
                error: function(message) {
                    console.error('[apiTester] Ajax Call Failed!');
                    console.log('[apiTester] ############ reply message is as below ############');
                    console.info(HTMLfileChineseCharCheck(message));
                    console.log('[apiTester] ############ reply message ends ############');
                }
            })
        }
    }

    return {
        post: post,
        get: get,
        test: test
    }
})();


fastInquire = (function() {

    var onClick, // event handler
        ajaxCall //util methods
    ;

    onClick = function(event) {

        $(this).text('请稍等...');
        var tmp_token = $('meta[name="csrf-token"]').attr('content');
        if (tmp_token) $.ajaxSetup({ headers: { 'X-CSRF-TOKEN': tmp_token } });


        /* setting up parameters */
        var tmp_dataAttributes = $(this).data();
        var data = {},
            method = $(this).data('method'),
            base_url = $(this).data('url'),
            url = "";

        for (var param in tmp_dataAttributes) {
            if (tmp_dataAttributes.hasOwnProperty(param)) {
                if (param.indexOf('param') >= 0) {
                    data[param.split('param')[1].toLowerCase()] = tmp_dataAttributes[param];
                }
            }
        }

        if (method === 'post') {
            data = JSON.stringify(data);
            url = base_url;
        } else if (method === 'get') {
            url = base_url + '?' + $.param(data);
        }

        var that = this;

        $.ajax({
            url: url,
            contentType: "application/json; charset=UTF-8",
            data: method === 'post' ? data : '',
            dataType: 'text',
            method: method,
            success: function(message) {
                //alert('服务器回复为成功！');
                var tmp_data = $(that).data();


                if (tmp_data.replyformat) {
                    //console.log(tmp_data.replyformat);
                    //console.log( (new RegExp(tmp_data.replyformat)).test(message) );

                    if (!(new RegExp(tmp_data.replyformat)).test(message)) {
                        //error! reply not match acceptable format

                        var r = /\\u([\d\w]{4})/gi;
                        message = message.replace(r, function(match, grp) {
                            return String.fromCharCode(parseInt(grp, 16));
                        });
                        message = unescape(message);

                        console.log(message);

                        // alert(decodeURIComponent(message));
                        //console.error('[Articulate-fastInquire] ' + message);
                        $(that).text('失败');
                        return false;
                    }
                }

                if (tmp_data.display) {
                    var tmp_displayMsg = tmp_data.display.replace(/_reply_/g, message);
                    $(that).text(tmp_displayMsg);
                } else $(that).text(message);
            },
            error: function(message) {
                var r = /\\u([\d\w]{4})/gi;
                message = JSON.stringify(message.responseText).replace(r, function(match, grp) {
                    return String.fromCharCode(parseInt(grp, 16));
                });
                message = unescape(message);

                console.log(message);
                $(that).text('失败');
            }
        })

    }

    jQuery.fn.extend({
        fastInquire: function() {
            return this.each(function() {
                $(this).on('click', onClick);
            })
        }
    });


})();



ajax_form = (function() {
    /*
        主要是针对要用ajax的form
    */

    var
        ajax_configMap, configMap, jqueryMap, stateMap, //system vars
        data2submit, // vars
        get_hiddenContent, ajax_setup, ajax_call, extract_input, setJquerymap, configValidate, validateData, validateData_realtime, checkAutoFilled, displayInvalidMsg, clearInvalidMsg, init_test, serverMsgHandler, //util methods
        view_ajaxCallSend, inject_invalidMsgHolders, // DOM methods
        onClickSubmit, OnanyReplyReceived, onLeaveInput, onLeaveRealtimeValidInput, onClearAll, onClearAllInvalidMsg, //event handler
        init, config, submit //public methods

    ;

    // initialization
    stateMap = {
        executing_formName: null
    };
    ajax_configMap = {}; // 用来保存对应的ajax设置
    configMap = {};
    jqueryMap = {};



    //- [ ] ajaxform validate部分 错误提示和错误位置提示都要用单独的参数和method

    validateData_realtime = function() {

    }

    // util methods

    serverMsgHandler = function(formName, message) {
        var caught_f = false;
        /*
            ajax response 成功：1 被抓住的返回 － serverMsgHandler； 
                               2 其他返回 － 触发 successCallback； 

            ajax response 失败：1 被抓住的返回 － 触发 serverMsgHandler； 
                               2 其他返回 － 触发errorCallback

           */


        /* 先看有没有responseText 有的话就用text 没有的话就用message本身 解决了不同的情况*/
        var responseText = (message.responseText) ? message.responseText : message;
        if (Array.isArray(responseText)) responseText = responseText.toString();

        var msgHandler = configMap[formName].serverMsgs;
        var errorList = msgHandler.errorResponse;

        for (var i = 0; i < errorList.length; i++) {
            if (responseText.indexOf(errorList[i].responseText) >= 0) { //找到了   | 这种判断方式是基于dataType为string的基础之上！！
                console.log('error matched!');
                console.log(errorList[i]);

                if (errorList[i].target === '_alert') alert(errorList[i].message);
                else {
                    var $tmp_errorField = jqueryMap[formName].$form.find('input[name="' + errorList[i].target + '"]');
                    displayInvalidMsg($tmp_errorField, errorList[i].message);
                    configMap[formName].showInvalid($tmp_errorField);
                }
                caught_f = true;
            }
            /* 因为本地的测试中就会将错误的信息还原（原来错误的信息标注后 改成之后被消除）；所以不需要在这里再做还原了！ */
        }


        return caught_f ? 'caught' : 'uncaught';
    }

    init_test = function(formName, testfield_settings) {
        for (var field in testfield_settings) {
            if (testfield_settings.hasOwnProperty(field)) {
                jqueryMap[formName].$form.find('input[name="' + field + '"]').val(testfield_settings[field]).addClass('filled');
            }
        }
    }

    clearInvalidMsg = function(formName) {
        jqueryMap[formName].$form.find('span.invalid_msg').text('').removeClass('state-displaying');
    }

    displayInvalidMsg = function($inputField, msgContent) {

        msgContent = msgContent || 'default message!';
        $inputField.siblings('span.invalid_msg').addClass('state-displaying').text(msgContent);
        $inputField.find('span.invalid_msg').addClass('state-displaying').text(msgContent);
    }

    checkAutoFilled = function(index) {
        /*
                NOT WORKING !
        */

        //$(this).addClass('filled');
        if ($(this).val()) $(this).addClass('filled');
    }

    validateData = function(formName) {

        var invaliFields = [],
            f_valid = true;

        /*
            检查分为三轮
            第一轮特殊检查 第二轮format检查 第三轮required检查 
            如此安排的原因是 后一轮的invalid message可以将前一轮覆盖掉 越到后面则优先级越高
        */

        // ######################################################
        //  第一轮 特殊检查
        // ######################################################
        // 如果是改密码的话： 两次的密码不能相同
        if (jqueryMap[formName].$compare_passwords.length > 1) {
            if (jqueryMap[formName].$compare_passwords.eq(0).val() === jqueryMap[formName].$compare_passwords.eq(1).val()) {
                invaliFields.push(jqueryMap[formName].$compare_passwords);
                displayInvalidMsg(jqueryMap[formName].$compare_passwords.eq(1), '新密码不能与旧密码相同');
                f_valid = false;
            } else configMap[formName].showValid(jqueryMap[formName].$compare_passwords); //先不匹配 后匹配之后 回复颜色
        }

        // 存在输入多个密码的情况
        if (jqueryMap[formName].$passwordFields.length > 2) {
            var password_content = null,
                password_confirmation_content = null,
                $tmp_confirmPswField;
            for (var i = 0; i < jqueryMap[formName].$passwordFields.length; i++) {
                var $tmp_passwordField = jqueryMap[formName].$passwordFields.eq(i);

                if ($tmp_passwordField.attr('name') === 'password') password_content = $tmp_passwordField.val();
                else if ($tmp_passwordField.attr('name') === 'password_confirmation') {
                    password_confirmation_content = $tmp_passwordField.val();
                    $tmp_confirmPswField = $tmp_passwordField;
                }
            }
            if (password_content != password_confirmation_content) {
                invaliFields.push(jqueryMap[formName].$passwordFields);
                displayInvalidMsg($tmp_confirmPswField, '两次输入的密码不匹配！');
                f_valid = false;
            } else {
                configMap[formName].showValid(jqueryMap[formName].$passwordFields);
            }
        } else if (jqueryMap[formName].$passwordFields.length > 1) {
            // 假设最多也只会有两个 密码栏
            if (jqueryMap[formName].$passwordFields.eq(0).val() != jqueryMap[formName].$passwordFields.eq(1).val()) {
                invaliFields.push(jqueryMap[formName].$passwordFields);
                displayInvalidMsg(jqueryMap[formName].$passwordFields.eq(1), '两次输入的密码不匹配！');
                f_valid = false;
            } else configMap[formName].showValid(jqueryMap[formName].$passwordFields); //先不匹配 后匹配之后 回复颜色
        }

        // ####################################################
        //   第2轮检查 各项是否符合format要求 
        // ####################################################

        for (var i = 0; i < jqueryMap[formName].$inputCollection.length; i++) {
            var $tmp_inputcell = jqueryMap[formName].$inputCollection.eq(i);

            if ($tmp_inputcell.data('format') && $tmp_inputcell.val()) { //如果不填内容 则不进行format检查
                console.log($tmp_inputcell.data('format'));
                console.log($tmp_inputcell.val());

                if (!(new RegExp($tmp_inputcell.data('format'))).test($tmp_inputcell.val())) {
                    invaliFields.push($tmp_inputcell);
                    displayInvalidMsg($tmp_inputcell, $tmp_inputcell.data('formatmsg') ? $tmp_inputcell.data('formatmsg') : '本项输入不合法');
                    f_valid = false;
                } else configMap[formName].showValid($tmp_inputcell);
            }
        }

        // ######################################################
        // 第3轮检查, required是否都已填  (对于除radio之外的信息的检查)
        // ######################################################


        for (var i = 0; i < jqueryMap[formName].$inputCollection.length; i++) {
            var $tmp_inputcell = jqueryMap[formName].$inputCollection.eq(i);
            if ($tmp_inputcell.prop('required') == true) {
                if ($tmp_inputcell.val() === '' || $tmp_inputcell.val() === undefined || $tmp_inputcell.val() === null) {
                    invaliFields.push($tmp_inputcell);
                    displayInvalidMsg($tmp_inputcell, '请输入本项');
                    f_valid = false;
                } else configMap[formName].showValid($tmp_inputcell);
            }
        }

        for (var i = 0; i < jqueryMap[formName].$radioGroups.length; i++) {
            var $tmp_radioGroup = jqueryMap[formName].$radioGroups.eq(i);

            if ($tmp_radioGroup.find("input[type='radio']").eq(0).prop('required') === true) {
                if ($tmp_radioGroup.find("input:checked").length < 1) {

                    invaliFields.push($tmp_radioGroup);
                    displayInvalidMsg($tmp_radioGroup, '请选择本项');
                    f_valid = false;
                }
            }
        }

        // 成功的话return true
        // 失败的话在合适的地方显示不正确
        for (var i = 0; i < invaliFields.length; i++) { configMap[formName].showInvalid(invaliFields[i]); }
        return f_valid;
    }

    get_hiddenContent = function(formName, $formElem) {
        var hidden_data = {};

        var $tmp_hiddenContents = $formElem.find("input[type='hidden']");

        for (var i = 0; i < $tmp_hiddenContents.length; i++) {
            var $tmp_hiddenContent = $tmp_hiddenContents.eq(i);
            if ($tmp_hiddenContent.hasClass('notsent')) continue;

            hidden_data[$tmp_hiddenContent.attr('name')] = $tmp_hiddenContent.val();
        };
        return hidden_data;
    }

    ajax_setup = function(formName, data) { // final ajax setups
        // 如果有token的话 设置好token
        var tmp_token = $('meta[name="csrf-token"]').attr('content');

        if (tmp_token) {
            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': tmp_token
                }
            });

            console.log('ajax token is set:' + tmp_token);
        }

        if (configMap[formName].finalDataConfig)
            data = configMap[formName].finalDataConfig(data);
        // if (data === false) return false;

        if (ajax_configMap[formName].method.match(/post/gi) != null) {
            ajax_configMap[formName].data = JSON.stringify(data);
        } else if (ajax_configMap[formName].method.match(/get/gi) != null) {
            ajax_configMap[formName].url = configMap[formName].url + '?' + $.param(data);
        }

        //console.log(data);

    }

    ajax_call = function(formName) {
        /* test only */

        //ajax_configMap[formName].data = 
        console.log('in ajax_call');

        console.log('---- ajax call is about to be made : parameters are as below ----- ');
        console.log(ajax_configMap[formName]);

        $.ajax(ajax_configMap[formName]);
    }

    extract_input = function(formName) {
        //select/input text/input radio.checkbox/input email.password./ input 
        // jqueryMap[formName].$form  ---- 对应的form
        var data = {},
            $tmp_inputItem, $tmp_radioItem,
            tmp_radioFields = {};

        // get the content of normal inputs(including checkboxes), select
        for (var i = 0; i < jqueryMap[formName].$inputCollection.length; i++) {
            $tmp_inputItem = jqueryMap[formName].$inputCollection.eq(i);

            var tmp_type = $tmp_inputItem.attr('type'),
                tmp_attrName = $tmp_inputItem.attr('name'),
                tmp_attrVal = tmp_type === 'checkbox' ? $tmp_inputItem.prop('checked') : $tmp_inputItem.val();
            // 如果是提取checkbox的值 就用prop('checked') 而不用val() --> 感觉主要对输入栏使用

            if (tmp_attrName && !$tmp_inputItem.hasClass('notsent') && tmp_attrVal) {
                data[tmp_attrName] = tmp_attrVal;
            }
        }

        // deal with radio groups
        for (var i = 0; i < jqueryMap[formName].$radioCollection.length; i++) {
            $tmp_radioItem = jqueryMap[formName].$radioCollection.eq(i);
            if ($tmp_radioItem.prop('checked')) {
                if ($tmp_radioItem.val()) tmp_radioFields[$tmp_radioItem.attr('name')] = $tmp_radioItem.val();
            }
        }
        for (var field in tmp_radioFields) {
            if (tmp_radioFields.hasOwnProperty(field)) {
                if (tmp_radioFields[field]) data[field] = tmp_radioFields[field];
            }
        }

        $.extend(data, get_hiddenContent(formName, jqueryMap[formName].$form));

        // 几乎是专用于transfer页面的  
        if (data.direction && data.amount) {
            if (data.direction === 'toMain') data.amount = -data.amount;
            delete data.direction;
        }

        return data;
    }

    setJquerymap = function(formName, $formElem) {
        jqueryMap[formName] = {};
        jqueryMap[formName].$form = $formElem;
        jqueryMap[formName].$username = $formElem.find("input[name='username']");
        jqueryMap[formName].$password = $formElem.find("input[type='password']");
        jqueryMap[formName].$compare_passwords = $formElem.find("input[name='old_password'], input[name='password']");
        jqueryMap[formName].$email = $formElem.find("input[type='email']");
        jqueryMap[formName].$submitBtn = $formElem.find("[type='submit']");
        jqueryMap[formName].$clearBtn = $formElem.find('.clearAll');
        jqueryMap[formName].$passwordFields = $formElem.find("input[type='password']");
        jqueryMap[formName].$mobile = $formElem.find("input[name='mobile']");
        jqueryMap[formName].$inputCollection = $formElem.find("input:not(input[type='submit'], input[type='hidden'], input[type='button'], input[type='radio']), select, textarea");
        jqueryMap[formName].$radioCollection = $formElem.find("input[type='radio']");


        //console.log(jqueryMap[formName].$form);
        // for (let i = 0; i < jqueryMap[formName].$inputCollection.length; i++) {
        //     var $tmp_inputItem = jqueryMap[formName].$inputCollection.eq(i);
        //     console.log($tmp_inputItem.get(0));

        // }
        // //console.log(jqueryMap[formName].$inputCollection);

        // find the smallest group to contain multiple radios(assume the radios should be of the same format; 
        // and radios have to be more than 1)
        // jqueryMap[formName].$radioGroups = jqueryMap[formName].$radioCollection.parent();
        // while (true) {
        //     if (jqueryMap[formName].$radioGroups.find("input[type='radio']").length > 1) break;
        //     else jqueryMap[formName].$radioGroups = jqueryMap[formName].$radioGroups.parent();
        // }

        // console.log(jqueryMap[formName].$radioGroups);

        jqueryMap[formName].$radioGroups = jqueryMap[formName].$radioCollection.parent().parent();

    }

    configValidate = function(formName) {
        // used to check if necessary part of the ajax call is set
        if (ajax_configMap[formName].url === undefined || ajax_configMap[formName].url === null || ajax_configMap[formName].url === "") {
            console.warn(" [[ Articulate.js ]] URL is not set! ");
        }
    }

    // DOM methods
    inject_invalidMsgHolders = function(formName) {
        // var $tmp_invalidMsgHolder = ;
        jqueryMap[formName].$inputCollection.parent().append($('<span/>').addClass('invalid_msg').text('????'));
        jqueryMap[formName].$radioGroups.append($('<span/>').addClass('invalid_msg').text('????'));
    }

    view_ajaxCallSend = function(formName) {
        jqueryMap[formName].$submitBtn.prop('disabled', true);
        jqueryMap[formName].$form.find('.formSubmitStyle').addClass('formSubmitted');
    }


    // event handler
    OnanyReplyReceived = function(data, textStatus, jqXHR) {
        // console.log(data);
        // console.log(textStatus);
        // console.log(jqXHR);

        jqueryMap[stateMap.executing_formName].$submitBtn.prop('disabled', false);
        jqueryMap[stateMap.executing_formName].$form.find('.formSubmitStyle').removeClass('formSubmitted');

        if (configMap[stateMap.executing_formName].clearUponComplete) {
            //console.log('clear upon complete set!');

            //jqueryMap[stateMap.executing_formName].$inputCollection.val(""); //
            for (var i = 0; i < jqueryMap[stateMap.executing_formName].$inputCollection.length; i++) {
                var $tmp_inputItem = jqueryMap[stateMap.executing_formName].$inputCollection.eq(i);
                if (!$tmp_inputItem.hasClass('nottoclear')) $tmp_inputItem.val("");
            }

            jqueryMap[stateMap.executing_formName].$radioCollection.prop('checked', false);
        } else if (configMap[stateMap.executing_formName].clearUponSuccess && data.status == 200) {
            for (var i = 0; i < jqueryMap[stateMap.executing_formName].$inputCollection.length; i++) {
                var $tmp_inputItem = jqueryMap[stateMap.executing_formName].$inputCollection.eq(i);
                if (!$tmp_inputItem.hasClass('nottoclear')) $tmp_inputItem.val("");
            }

            jqueryMap[stateMap.executing_formName].$radioCollection.prop('checked', false);
        }

        stateMap.executing_formName = null;
    }

    onClearAllInvalidMsg = function(event, formName) {
        //清除各个input项的invalid msg
        clearInvalidMsg(formName);

        //清除各个input项的invalid输出
        for (var i = 0; i < jqueryMap[formName].$inputCollection.length; i++) {
            configMap[formName].showValid(jqueryMap[formName].$inputCollection.eq(i));
        }
    }

    onClearAll = function(event) {
        var formName = $(this).closest('.articulate_ajaxForm').attr('id');

        event.preventDefault();

        // clear all inputs
        jqueryMap[formName].$inputCollection.val("");
        jqueryMap[formName].$radioCollection.prop('checked', false);

        // clear all invalid notifications
        jqueryMap[formName].$form.trigger('clearAllInvalidMsg', formName);

        // custom clear settings
        if (configMap[formName].externalClear) configMap[formName].externalClear(jqueryMap[formName].$form);
    }

    onClickSubmit = function(event) {
        var formName = $(this).closest('.articulate_ajaxForm').attr('id');

        event.preventDefault();

        console.log(formName);

        clearInvalidMsg(formName); // 先清空提示 在开始验证；以免之前的错误提示继续显示
        if (validateData(formName)) {
            view_ajaxCallSend(formName);
            stateMap.executing_formName = formName;

            submit(formName);
        }
    }
    onLeaveInput = function(event) {
        var $field_title = $(this).closest('label').find('span.field_title');
        if ($field_title.get(0)) {
            if ($(this).val()) $(this).addClass('filled');
            else $(this).removeClass('filled');
        }
    }

    onLeaveRealtimeValidInput = function(event) {
        /*
                //stateMap.thisform.realTimeValidation.username = {url: xxxx, erroe_message: aaaaa}

                */


        //stateMap.

        //console.log(aaa);
    }

    // public methods
    init = function(formName, $formElem) {

        setJquerymap(formName, $formElem);
        inject_invalidMsgHolders(formName);

        // extract info from html
        ajax_configMap[formName] = {
            method: null,
            url: '',
            success: $.noop(),
            error: $.noop(),
            complete: OnanyReplyReceived,
            dataType: 'text' //datatype是一定会有的；因为server一定可能有reply； 而contentType在GET是不可能有需要
        };

        // console.log(ajax_configMap[formName]);

        ajax_configMap[formName].method = $formElem.attr('method');
        ajax_configMap[formName].url = $formElem.attr('action');

        if (ajax_configMap[formName].method.match(/post/gi) != null) {
            ajax_configMap[formName].contentType = "application/json; charset=UTF-8";
        }


        //$('label input').each(checkAutoFilled);  // 先检查本项是否已经自动填写


        // attach event handlers
        for (var i = 0; i < jqueryMap[formName].$inputCollection.length; i++) {
            var $tmp_inputItem = jqueryMap[formName].$inputCollection.eq(i);
            if ($tmp_inputItem.data('realtimeValidation')) {
                $tmp_inputItem.on('blur', onLeaveRealtimeValidInput);

                stateMap[formName] = { realTimeValidation: {} }
                var tmp_input_field = $tmp_inputItem.attr('name');
                $.extend(stateMap[formName].realTimeValidation[tmp_input_field] = {
                    url: $(this).data('realtimeValidation'),
                    error_message: $(this).data('invalidMsg')
                });
            };
        }
        jqueryMap[formName].$submitBtn.on('click', onClickSubmit);
        jqueryMap[formName].$clearBtn.on('click', onClearAll);
        //onClearAllInvalidMsg
        jqueryMap[formName].$form.on('clearAllInvalidMsg', onClearAllInvalidMsg); //destroy

        $('label input').on('blur', onLeaveInput); // 给有内容的input加上 filled class； 多用于style的提示
    }

    config = function(formName, configs) { //mainly used for callback registration


        configMap[formName] = {};
        configs = configs || {};

        configMap[formName].showValid = $.noop();
        configMap[formName].showInvalid = $.noop();

        if (configs.method) ajax_configMap[formName].method = configs.method;
        if (configs.dataType) ajax_configMap[formName].dataType = configs.dataType;
        if (configs.contentType) ajax_configMap[formName].contentType = configs.contentType;
        if (configs.url) ajax_configMap[formName].url = configs.url;
        configMap[formName].url = ajax_configMap[formName].url;
        // 将url备份一份 这是因为在发送get请求时会改变url 需要有备份


        if (configs.serverMsgs) {
            var tmp_successCallback = configs.successCallback || $.noop(),
                tmp_errorCallback = configs.errorCallback || $.noop();

            configMap[formName].serverMsgs = configs.serverMsgs;

            /*

            ajax response 成功：1 ideal的返回 － 触发successCallback； 2 预料到的返回 － 触发 serverMsgHandler； 
                              3 未预料到的返回 － 会与errorCallback有相同的处理

            ajax response 失败：1 预料到的返回 － 触发 serverMsgHandler； 2 未预料到的返回 － 触发errorCallback

           */

            //无论成功或失败 都先去抓
            ajax_configMap[formName].success = function(message) {
                if (serverMsgHandler(stateMap.executing_formName, message) === 'uncaught') {
                    tmp_successCallback(message);
                }

                // var if_serverMsgHandled = serverMsgHandler(stateMap.executing_formName, message);

                // if (if_serverMsgHandled === 'success') tmp_successCallback(message);
                // else if (if_serverMsgHandled === 'uncaught') tmp_errorCallback(message);

            }
            ajax_configMap[formName].error = function(message) {
                if (serverMsgHandler(stateMap.executing_formName, message) === 'uncaught') {
                    tmp_errorCallback(message);
                }
            }
        } else {
            if (configs.successCallback) ajax_configMap[formName].success = configs.successCallback;
            if (configs.errorCallback) ajax_configMap[formName].error = configs.errorCallback;
        }

        if (configs.showInvalid) configMap[formName].showInvalid = configs.showInvalid;
        if (configs.showValid) configMap[formName].showValid = configs.showValid;

        if (configs.test) init_test(formName, configs.test);
        if (configs.clearUponComplete) configMap[formName].clearUponComplete = true;
        if (configs.clearUponSuccess) configMap[formName].clearUponSuccess = true;

        if (configs.finalDataConfig) configMap[formName].finalDataConfig = configs.finalDataConfig;

        if (configs.externalClear) configMap[formName].externalClear = configs.externalClear;

        configValidate(formName);
    }

    submit = function(formName) {
        // if (!ajax_setup(formName, extract_input(formName))) return false;
        ajax_setup(formName, extract_input(formName));
        ajax_call(formName);
    }

    // add the module to jQuery prototype
    jQuery.fn.extend({
        ajax_form: function(configs) {
            init(this.attr('id'), this);
            config(this.attr('id'), configs);

        }
    });

    return {
        config: config,
        submit: submit
    }

})();


/*
        ####################################################################################

                                                DT 

        ####################################################################################

*/



DT = (function() {
    var
        inject_searchFilters, interpretCols, inject_summary, compile_summary, initDatePicker, updateData, inject_batchOperations,
        stateMap, configMap = {},
        onClickSearch, onDestroyCommand, onServerMsg, onNewRecordAdded, onDvdTable, //event handler
        init //public methods    
    ;

    //init
    stateMap = {
        tableName: null,
        dtObject: null,
        dtAPI: null,
        summaryDefs: null,
        $summarySection: null,
        searchDefs: null,
        if_multiSelectable: false,
        msgHandlerDefs: null,
        activeRowApi: null,
        dvdTableState: null
    };

    // configMap.languageDefs_english = {
    //     "lengthMenu": " 每页显示 _MENU_",
    //     "paginate": {
    //         "previous": "上一页",
    //         "next": "下一页",
    //         "last": "尾页",
    //         "first": "首页"
    //     },
    //     "info": "_TOTAL_ 条符合条件的记录 ｜ 正在显示第 _START_ 至 _END_ 条",
    //     "infoFiltered": "｜ 总共 _MAX_ 条记录",
    //     "zeroRecords": "没有找到匹配的记录",
    //     "emptyTable": "目前没有数据",
    //     "search": ''
    // };
    configMap.languageDefs_chinese = {
        "lengthMenu": " 每页显示 _MENU_",
        "paginate": {
            "previous": "上一页",
            "next": "下一页",
            "last": "尾页",
            "first": "首页"
        },
        "info": "_TOTAL_ 条符合条件的记录 ｜ 正在显示第 _START_ 至 _END_ 条",
        "infoFiltered": "｜ 总共 _MAX_ 条记录",
        "zeroRecords": "没有找到匹配的记录",
        "emptyTable": "目前没有数据",
        "search": ''
    };



    //event handler 

    onServerMsg = function(event, msgType, msgContent) { //

        if (stateMap.msgHandlerDefs[msgType] === null) {
            alert('message handler error!');
            return false;
        }
        if (stateMap.activeRowApi) stateMap.msgHandlerDefs[msgType](msgContent, stateMap.activeRowApi);
    }

    onNewRecordAdded = function(event, record) {
        console.log('onNewRecordAdded invoked');
        stateMap.dtAPI.row.add(record).draw();
    }


    // 可能需要加一个dvdTable
    onDvdTable = function(event, param) {
        stateMap.dtAPI.columns().search('');
        // stateMap.dtAPI.draw();

        stateMap.dvdTableState = param;

        if (!param) {} else {
            for (var column in param) {
                if (param.hasOwnProperty(column)) {
                    stateMap.dtAPI.column(column + ':name').search(param[column]);
                }
            }
        }

        stateMap.dtAPI.draw();
    }

    onDestroyCommand = function(event) {

        stateMap.dtAPI.destroy(false);

    }

    onClickSearch = function(event) {

        var criterias = stateMap.searchDefs.criterias;
        var type, kw, checkboxFilters = {},
            selection = [],
            timeRange = {},
            f_datePicker = false;


        for (var i = 0; i < criterias.length; i++) {
            if (criterias[i].title === 'type') {
                type = $('label[class*="FILTERtype"] > select').val();
            } else if (criterias[i].title === 'keyWord') {
                kw = $('label[class*="FILTERkeyWord"] > input').val();
            } else if (criterias[i].type === 'checkbox') {
                var tmp_filterArray = checkboxFilters[criterias[i].data];
                if (!tmp_filterArray) tmp_filterArray = [];
                if ($('label[class*="FILTER' + criterias[i].title + '"] > input').prop("checked"))
                    tmp_filterArray.push(criterias[i].lookfor);
                checkboxFilters[criterias[i].data] = tmp_filterArray;
            } else if (criterias[i].type === 'select' && criterias[i].title != 'type') {
                var tmp_selection_item = [];
                tmp_selection_item.push(criterias[i].data);
                tmp_selection_item.push($('label[class*="' + criterias[i].title + '"] > select :selected').text());
                selection.push(tmp_selection_item);
            } else if (criterias[i].type === 'datePicker') {
                if ($('#startDatePicker').val() || $('#endDatePicker').val()) f_datePicker = true;
            }
        }

        // clear last searches first
        stateMap.dtAPI.columns().search('');
        if (stateMap.dvdTableState) {
            // 如果当前存在着dividing table的情况

            var param = stateMap.dvdTableState;
            for (var column in param) {
                if (param.hasOwnProperty(column)) {
                    stateMap.dtAPI.column(column + ':name').search(param[column]);
                }
            }
        }

        var tmp_table = stateMap.dtAPI;

        // type + kw
        if (type) tmp_table = tmp_table.column(type + ':name').search(kw); //type没有设置时不处罚search

        // checkbox
        for (var dataField in checkboxFilters) {
            if (checkboxFilters.hasOwnProperty(dataField)) {

                if (checkboxFilters[dataField].length > 0) { //存在选择
                    var tmp_regxr = '';

                    for (var i = 0; i < checkboxFilters[dataField].length; i++) {
                        tmp_regxr += (checkboxFilters[dataField][i] + '|');
                    }
                    tmp_regxr = tmp_regxr.slice(0, -1);

                    tmp_table = tmp_table.column(dataField + ':name').search(tmp_regxr, true, false);
                    console.log(dataField);

                }
            }
        }

        //select 
        for (var i = 0; i < selection.length; i++) {
            if (!selection[i][1].startsWith('请选择') && !selection[i][1].startsWith('全部') && !selection[i][1].startsWith('不限')) {
                tmp_table = tmp_table.column(selection[i][0] + ':name').search('^' + selection[i][1] + '$', true, false);
            }
        }

        if (f_datePicker) {
            tmp_table.column('applyTime:name').search(generateRegExp(string2Date('startDate', $('#startDatePicker').val()), string2Date('endDate', $('#endDatePicker').val())), true); //applyTime
        }

        tmp_table.draw();

    }


    //util methods

    updateData = function(rowNode, newData) {
        stateMap.dtAPI.row(rowNode).data(newData).draw();
    }


    // how to use summaryDefs
    /*

    summaryDefs: [
        {
            targetData: '', // name
            range: '', //current / all(不知道是不是 all)
            method:  // 不填就是取总 填average就是平均值
            displayContext:  // 输出的格式 string  __result__部分会被替换掉
            preprocessing:  // 一个function 每个value可以预处理再加上去
        }

    ]

    */

    /*
    if (stateMap.dvdTableState) {
            // 如果当前存在着dividing table的情况

            var param = stateMap.dvdTableState;
            for (var column in param) {
                if (param.hasOwnProperty(column)) {
                    stateMap.dtAPI.column(column + ':name').search(param[column]);
                }
            }
        }

        findme
        */

    compile_summary = function(summaryDefs) {
        var tmp_tableAPI = stateMap.dtAPI;

        var output_array = [];
        for (var i = 0; i < summaryDefs.length; i++) {
            var tmp_result;
            tmp_result = tmp_tableAPI
                .column(summaryDefs[i].targetData + ':name', { 'page': summaryDefs[i].range, 'search': 'applied' })
                .data()
                .reduce(function(total, val) {
                    if (summaryDefs[i].preprocessing) val = summaryDefs[i].preprocessing(val);
                    total += Number(val.match(/-?\d+(\.\d+)?/)[0]);
                    return total;
                }, 0);

            if (summaryDefs[i].method === 'average') tmp_result =
                (tmp_result / tmp_tableAPI.column(summaryDefs[i].targetData + ':name', { 'page': summaryDefs[i].range }).data().length);

            tmp_result = tmp_result.toFixed(2);

            output_array.push(summaryDefs[i].displayContext.replace("__result__", tmp_result));
        }

        return output_array;

    }

    interpretCols = function(colDefs) {
        //deal with custom settings
        for (var i = 0; i < colDefs.length; i++) {
            if (colDefs[i].myCustom) {
                //console.log(colDefs[i]);
                if (colDefs[i].myCustom === 'actions') {

                    var cellContent = "";
                    // 如果dropdown设置为false 或者 只有一个action 就不用dropdown； 其他时候都用dropdown

                    if (colDefs[i].dropdownMenu === false || (colDefs[i].dropdownMenu != true && colDefs[i].actions.length <= 1)) {
                        for (var j = 0; j < colDefs[i].actions.length; j++) {
                            cellContent += "<button class=\"btn btn-link btn-sm " + (colDefs[i].actions[j].classes || '') + "\" type=\"button\">" + colDefs[i].actions[j].displayTitle + "<\/button>";
                        }

                    } else {
                        cellContent += (
                            "<div class=\"dropdown operation\">" + "  <button class=\"btn btn-info btn-sm dropdown-toggle\" type=\"button\" data-toggle=\"dropdown\">" + (colDefs[i].buttonTitle ? colDefs[i].buttonTitle : '操作') + "  <span class=\"caret\"><\/span><\/button>" + "  <ul class=\"dropdown-menu\">"
                        );
                        for (var j = 0; j < colDefs[i].actions.length; j++) {
                            cellContent += '<li><a href=\"javascript:;\" class="' + (colDefs[i].actions[j].classes || '') + '">' + colDefs[i].actions[j].displayTitle + '<\/a><\/li>';
                        }
                        cellContent += "<\/ul> <\/div>";
                    }

                    $.extend(colDefs[i], {
                        title: colDefs[i].colTitle ? colDefs[i].colTitle : '操作',
                        data: null,
                        defaultContent: cellContent,
                        "orderable": false
                    });


                } // end of [colDefs[i].myCustom === 'actions']
                else if (colDefs[i].myCustom === 'selectBox') {
                    //view
                    $.extend(colDefs[i], {
                        title: "<input type='checkbox' class='selectAll' /> ",
                        data: null,
                        defaultContent: "<input type='checkbox' class='select' /> ",
                        "orderable": false
                    });

                    stateMap.if_multiSelectable = true;
                    // event handler
                    /*
                    $table.find('tbody').on('click', 'tr .' + callbackDefs[i].target, function() {
                        callbackDefs[i].method(stateMap.dtAPI.row($(this).closest('tr')).data());
                    });
                    */
                }

                // 清理custom的设置中 不可被dataTable识别的部分
                delete(colDefs[i].myCustom);
                delete(colDefs[i].actions);
                delete(colDefs[i].dropdownMenu);

            } //end of [colDefs[i].myCustom] has value
            else if (colDefs[i].format) {
                $.extend(colDefs[i], {
                    render: function(i, data, type, full, meta) {

                        if (data == null) return ''; //没有数据的情况
                        return colDefs[i].format.replace(/__.*?__/gi, function myFunction(x) {
                            x = x.slice(2, -2);

                            if (x.match(/\./).length > 1) {
                                console.error('column def farmat error!');
                                return null;
                            } else if (x.match(/\./).length == 1) {
                                return Number(data).toFixed(x.split('.')[1]);
                                // console.log(x);
                            } else return data;
                        });
                    }.bind(this, i)
                });
                // delete colDefs[i].format;
            }
        }
        return colDefs;
    }

    inject_batchOperations = function($batchOpsContainer, batchOpsDefs, tableAPI) {

        var $tmp_button, $tmp_link;
        //没有dropdown
        if (batchOpsDefs.dropdownMenu === false) {
            for (var i = 0; i < batchOpsDefs.actions.length; i++) {
                $tmp_button = $('<button/>').addClass('btn btn-primary').attr({ type: 'button' }).text(batchOpsDefs.actions[i].displayTitle);

                $tmp_button.on('click', function(i) {
                    batchOpsDefs.actions[i].method(tableAPI.rows('.selected').data());
                }.bind(this, i));

                $batchOpsContainer.append($tmp_button);
            }

            return false;
        }

        var $tmp_dropdown = $('<div/>').addClass('dropdown batchOperations'),
            $tmp_mainBtn = $('<button/>').addClass('btn btn-primary dropdown-toggle').attr({ type: 'button', 'data-toggle': 'dropdown' }).html('批量操作<span class=\"caret\"><\/span><\/button>'),
            $tmp_ul = $('<ul/>').addClass('dropdown-menu');

        for (var i = 0; i < batchOpsDefs.actions.length; i++) {
            $tmp_link = $('<a/>').text(batchOpsDefs.actions[i].displayTitle);
            $tmp_button = $('<li/>').append($tmp_link);
            $tmp_ul.append($tmp_button);

            $tmp_link.on('click', function(i) {
                batchOpsDefs.actions[i].method(tableAPI.rows('.selected').data());
            }.bind(this, i));

        }

        $tmp_dropdown.append($tmp_mainBtn);
        $tmp_dropdown.append($tmp_ul);

        $batchOpsContainer.html($tmp_dropdown);
    }


    inject_summary = function() {
        if (!stateMap.dtAPI) return false; // table尚未初始化完成
        if (!stateMap.summaryDefs) return false; // 没有设置summaryDefs的时候

        stateMap.$summarySection.html('');

        var summaryList = compile_summary(stateMap.summaryDefs);
        for (var i = 0; i < summaryList.length; i++) {
            stateMap.$summarySection.append($('<blockquote/>').html(summaryList[i]));
        }
    }


    initDatePicker = function() {

        if (!jQuery().jeDate) {
            console.error('[[ArticulateJS]] jeDate plugin is not found!');
            return false;
        }
        var start = {
            format: "YYYY-MM-DD hh:mm:ss",
            minDate: '2014-06-16',
            festival: true,
            maxDate: $.nowDate(0),
            choosefun: function(elem, datas) {
                end.minDate = datas; //开始日选好后，重置结束日的最小日期
            },
            okfun: function(elem, datas) {
                start.maxDate = datas;
            },
            isTime: true
        };
        var end = {
            format: "YYYY-MM-DD hh:mm:ss",
            festival: true,
            maxDate: $.nowDate(0),
            choosefun: function(elem, datas) {
                start.maxDate = datas; //将结束日的初始值设定为开始日的最大日期
            },
            okfun: function(elem, datas) {
                start.maxDate = datas;
            },
            isTime: true
        };
        $("#startDatePicker").jeDate(start);
        $("#endDatePicker").jeDate(end);
    }

    inject_searchFilters = function($searchEl, searchDefs) {
        var HTMLcontent = "";
        var is_datePicker = false;

        for (var i = 0; i < searchDefs.criterias.length; i++) {
            var criteria = searchDefs.criterias[i];

            switch (criteria.type) {
                case 'select':
                    {
                        HTMLcontent += ('<label class="' + stateMap.tableName + '-FILTER' + criteria.title + '"> ' + criteria.displayTitle + '<select class="form-control input-xsmall input-inline">');
                        if (criteria.defaultContent) HTMLcontent += '<option value="">' + criteria.defaultContent + '</option>';
                        for (var j = 0; j < criteria.options.length; j++) {
                            HTMLcontent += ('<option value="' + criteria.options[j].value + '">' + criteria.options[j].display + '</option>');
                        }
                        HTMLcontent += ' </select> </label>';
                        break;
                    };
                case 'datePicker':
                    {
                        HTMLcontent += ('<label class="' + stateMap.tableName + '-FILTERstartdate"> <input type="text" id="startDatePicker" class="form-control input-small input-inline" placeholder="' + criteria.displayTitle.start + '" /> </label>');
                        HTMLcontent += '至';
                        HTMLcontent += ('<label class="' + stateMap.tableName + '-FILTERenddate"> <input type="text" id="endDatePicker" class="form-control input-small input-inline" placeholder="' + criteria.displayTitle.end + '" /> </label>');
                        is_datePicker = true;
                        break;
                    };
                case 'text':
                    { //input[type="text"]
                        HTMLcontent += ('<label class="' + stateMap.tableName + '-FILTER' + criteria.title + '"> ' + criteria.displayTitle + '<input type="text" class="form-control input-small input-inline" placeholder="' + (criteria.placeholder || "") + '"/> </label>');
                        break;
                    };
                case 'checkbox':
                    { //input[type="checkbox"]
                        HTMLcontent += ('<label class="' + stateMap.tableName + '-FILTER' + criteria.title + '"> ' + '<input type="checkbox" class=""/> ' + criteria.displayTitle + ' </label>');
                        // assume 
                        break;
                    };
                case 'lineBreaker':
                    {
                        HTMLcontent += "<div class='clearfix'> </div>";
                        break;
                    };
                default:
                    ;
            }
        }

        HTMLcontent += ('<button class="pull-right btn btn-success ' + stateMap.tableName + '-FILTERsubmit' + '" type="button">搜索<span class="glyphicon glyphicon-search"></span> </button>');
        $searchEl.html(HTMLcontent);

        if (is_datePicker) initDatePicker();
    }

    init = function($table, dataSource, colDefs, summaryDefs, searchDefs, languageDefs, DOMdefs, batchOpsDefs, pageLength, rowIdReference, callbackDefs, headerCallbackDefs, msgHandlerDefs) {
        $table.off();

        stateMap.tableName = $table.attr('id');


        colDefs = interpretCols(colDefs); /* 对个column的定义做一个预处理 */
        // if (!languageDefs) languageDefs = configMap.languageDefs;

        var tmp_language = configMap.languageDefs_chinese; //默认为chinese
        if (languageDefs && languageDefs.predefined == 'english') tmp_language = {};
        $.extend(tmp_language, languageDefs);

        // languageDefs = $.extend(configMap.languageDefs, languageDefs);

        if (summaryDefs) {
            stateMap.summaryDefs = summaryDefs;
        }

        var tmp_DOM = "<'row' <'col-sm-2'l> <'col-sm-10 searchFilter'f>>" + "<'row tableSummary'<'col-sm-12'> >" + "<'row'<'col-sm-12'tr>>" + "<'row'<'col-sm-5'i><'col-sm-7'p>>";
        if (batchOpsDefs) tmp_DOM = "<'row' <'col-sm-2'<'batch-operation'>> <'col-sm-2'l> <'col-sm-8 searchFilter'f>>" + "<'row tableSummary'<'col-sm-12'> >" + "<'row'<'col-sm-12'tr>>" + "<'row'<'col-sm-5'i><'col-sm-7'p>>";
        if (DOMdefs) tmp_DOM = DOMdefs; // 不管前面如何 如果存在DOMdefs到最后就会以之为准


        // 对于js数据源与server数据源的分别处理
        var tmp_dtObject = {
            columns: colDefs,
            // "orderCellsTop": true,
            "dom": tmp_DOM,
            "language": tmp_language,
            "pagingType": "full_numbers",
            "stateSave": false,
            "lengthMenu": [
                [5, 10, 20, -1],
                [5, 10, 20, "All"]
            ],
            "pageLength": pageLength ? Number(pageLength) : 10,
            "drawCallback": inject_summary,
            "rowId": rowIdReference ? rowIdReference : '',
            // "deferRender": true
        }
        if (Array.isArray(dataSource)) tmp_dtObject.data = dataSource;
        else if (typeof dataSource === 'string') {
            $.extend(tmp_dtObject, {
                "processing": true,
                "serverSide": true,
                'ajax': dataSource
            })
        } else {
            alert('data source undefined!');
            return false;
        }

        stateMap.dtObject = $table.dataTable(tmp_dtObject);

        stateMap.dtAPI = stateMap.dtObject.api();
        stateMap.$summarySection = $table.closest('.dataTables_wrapper').find('.tableSummary > .col-sm-12');
        inject_summary(); // 第一次draw的时候 table object都还没有生成 无法使用api；只有先手动call第一次

        if (searchDefs) {
            inject_searchFilters($('.searchFilter > .dataTables_filter'), searchDefs);

            // event handler binding
            $('.searchFilter > .dataTables_filter button[class*="FILTERsubmit"]').on('click', onClickSearch);
            stateMap.searchDefs = searchDefs;
        }

        if (batchOpsDefs) {
            inject_batchOperations($('.batch-operation'), batchOpsDefs, stateMap.dtAPI);
            //table.rows('.selected').data()
            // stateMap.dtAPI.row($(this).closest('tr')).data()
        }


        if (callbackDefs) {
            for (var i = 0; i < callbackDefs.length; i++) {
                $table.find('tbody').on('click', 'tr .' + callbackDefs[i].target, function(i, event) {
                    var $tmp_row = stateMap.dtAPI.row($(event.target).closest('tr'));
                    stateMap.activeRowApi = $tmp_row;
                    callbackDefs[i].method($tmp_row.data(), event);
                }.bind(this, i));
            }
        }

        if (headerCallbackDefs) {

            for (var i = 0; i < headerCallbackDefs.length; i++) {

                $table.find('thead').on('click', 'tr th span.' + headerCallbackDefs[i].target, function(i, event) {
                    event.preventDefault();

                    headerCallbackDefs[i].method(stateMap.dtAPI);

                    return false;
                }.bind(this, i));
            }
        }

        if (stateMap.if_multiSelectable) {
            $table.find('tbody').on('change', 'tr input[type="checkbox"].select', function() {
                var $row = $(this).closest('tr');
                if ($(this).prop('checked')) $row.addClass('selected');
                else $row.removeClass('selected');
            });

            $table.find('thead').on('change', 'tr input[type="checkbox"].selectAll', function() {
                //$(this).closest('tr').toggleClass('selected');
                $table.find('tr input[type="checkbox"].select').prop('checked', $(this).prop('checked')).change();
            });

        }

        $table.on('destroy', onDestroyCommand);
        $table.on('newRecord', onNewRecordAdded);
        $table.on('dvdTable', onDvdTable);

        if (msgHandlerDefs) {
            stateMap.msgHandlerDefs = msgHandlerDefs;
            $table.on('serverMsg', onServerMsg);
        }

        return stateMap.dtObject;
    }


    jQuery.fn.extend({
        DT: function(configs) {
            // 先判断必要的依赖是否都已经满足
            if (!jQuery().dataTable) {
                console.error('[ArticulateJS] dataTable plugin is not found!');
                return false;
            }

            //如果configs又出了上述之外的其他的设置 那就直接读入 放入dataTable
            return init(this, configs.dataSource, configs.colDefs, configs.summaryDefs, configs.searchDefs, configs.languageDefs, configs.DOMdefs, configs.batchOpsDefs, configs.pageLength, configs.rowIdReference, configs.callbackDefs, configs.headerCallbackDefs, configs.msgHandlerDefs);
        }
    });

    return {
        updateData: updateData
        /* 
                作为ajax call register的callback

                var new_data = {serial:'003', 'username': '外星人', name: '外星人 Zhang', agent: 'Articulate', promotionName: '买一送一', type: '充值返奖', time:'2016.12.6', state:'你管我？？'};

                setTimeout(function() {
                    DT.updateData( $('#depositAudit_table tbody tr').first() , new_data);
                }, 3000);
           
           */
    }

})();






/*
        ####################################################################################

                                         modalCtrl 

        ####################################################################################
*/

var modalContrl = function() {
    return (function() {
        var
            stateMap, jqueryMap, dataContainerMap, customSetup,
            init, config, open, close, toggle, populate, clearData, is_open //public methods
        ;

        //initialization
        stateMap = {
            url: null
        };
        jqueryMap = {
            $modal_wrapper: null,
            $modal: null,
            $modal_title: null,
            $modal_body: null
        }
        dataContainerMap = {};
        customSetup = null;


        //util method
        clearData = function() {
            if (dataContainerMap == {}) return false;
            for (var key in dataContainerMap) {
                if (dataContainerMap.hasOwnProperty(key)) {
                    for (var i = 0; i < dataContainerMap[key].length; i++) {
                        if (dataContainerMap[key][i].prop("tagName") == 'INPUT') dataContainerMap[key][i].val('');
                        else dataContainerMap[key][i].html('');
                    }
                }
            }
        }
        is_open = function() {
            return jqueryMap.$modal_wrapper.hasClass('show') ? true : false;
        }

        //public methods
        init = function($modalElm) {

            // setup visual parts
            $.extend(jqueryMap, {
                $modal_wrapper: $modalElm,
                $modal: $modalElm.find('.modal-dialog'),
                $modal_title: $modalElm.find('.modal-title'),
                $modal_body: $modalElm.find('.modal-body')
            })

            // 不知道会不会break什么
            $(document).off('keyup');
            $(document).on('keyup', function(e) {
                console.log('keyup triggered!');
                if (e.keyCode === 27) close(); // esc
            });

            //setup data parts
            $modalElm.find('.dataContainer').each(function(index) {
                var tmp_name = 'default';
                if ($(this).prop("tagName") == 'INPUT') tmp_name = $(this).attr('name');
                else tmp_name = $(this).data('field');

                if (dataContainerMap[tmp_name] && Array.isArray(dataContainerMap[tmp_name])) dataContainerMap[tmp_name].push($(this));
                else dataContainerMap[tmp_name] = [$(this)];

                // if ($(this).prop("tagName") == 'INPUT')  dataContainerMap[$(this).attr('name')] = $(this);
                // else dataContainerMap[$(this).data('field')] = $(this);
            });


        }
        config = function(configs) {

            if (configs.width) switch (configs.width) {
                case 'wide':
                    jqueryMap.$modal.removeClass('modal-full');
                    jqueryMap.$modal.addClass('modal-wide');
                    break;
                case 'full':
                    jqueryMap.$modal.removeClass('modal-wide');
                    jqueryMap.$modal.addClass('modal-full');
                    break;
                default:
                    {
                        jqueryMap.$modal.removeClass('modal-full');
                        jqueryMap.$modal.removeClass('modal-wide');
                        var tmp = configs.width;
                        jqueryMap.$modal.width(tmp);
                    }
            }

            if (configs.height) jqueryMap.$modal.height(configs.height);

            if (configs.title) jqueryMap.$modal_title.text(configs.title || "测试");

            if (configs.customSetup) {
                customSetup = configs.customSetup;
            } else customSetup = null; // 没有设置则清空 避免前一次config的干扰
        }

        open = function() {
            // 先清空data
            clearData();
            if (customSetup) {
                if (customSetup.setup && typeof customSetup.setup == 'function') customSetup.setup(customSetup.data, jqueryMap.$modal_wrapper);
            }

            if (!is_open()) jqueryMap.$modal_wrapper.modal("show");
        }

        close = function() {
            jqueryMap.$modal_wrapper.modal("hide");
            // clearData();
        }
        toggle = function() {
            jqueryMap.$modal_wrapper.modal("toggle");
        }


        populate = function(dataSet) {

            // 先清空
            for (var key in dataSet) {
                if (dataSet.hasOwnProperty(key)) {
                    if (dataContainerMap[key]) {
                        for (var i = 0; i < dataContainerMap[key].length; i++) {
                            if (dataContainerMap[key][i].prop("tagName") == 'INPUT') dataContainerMap[key][i].val(dataSet[key]);
                            else dataContainerMap[key][i].text(dataSet[key]);
                        }

                    }
                }
            }
        }


        return {
            init: init,
            config: config,
            populate: populate,
            open: open,
            close: close,
            toggle: toggle,
            clearData: clearData
        }

    })();
}

modalCtrl = modalContrl();